<template>
  <br><br><br><br>
  <div class="logistic-section">
    <div class="div_title">
      <context :context=cate b2b="true" />
    </div>
    <div class="card" >
      <read page='logistic'/>
    </div>
  </div>
  <br><br><br><br>
  <div class="ad-container" style="width:100%;text-align: center;">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
  <footerInfo />
</template>
<script>

import { ref, inject } from 'vue'
import ssr from '@/components/common/ssr'
import context from '@/components/common/Context.vue'
import read from '@/Read.vue'
import footerInfo from '@/components/common/footer'

export default {
  emits: ["setMenu", "login", "logout"],
  mounted() {
    this.addJsonLd()
    this.api.setPage('logistic')
    this.$emit("setMenu", {page:'logistic'})
    console.log(this.api.getCookies('page'))
  },  
  components: {
    footerInfo, context, read
  },
  data() {
    return {
      page:1,
    }
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 기업 > 블로그'
    const keywords = '물류,3pl,풀필먼트fulfillment,물류대행,기업물류,수출,수입,수출입,세관,통관,견적서,물류견적서,창고,해외창고'
    const description = 'GEEK9 해외 물류 서비스와 레퍼런스 소개'
    const geek9Url = ssr.GEEK9_URL + 'logistic'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL+"biz", description)
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const cate = ref([
      { label: '블로그', route: '/logistic' }
    ])
    return {
      addJsonLd, cate
    }
  },
  methods: {

  }
}
</script>
<style scoped>
  .quote-menu {
    width:100%;
    margin-right:5px;
    font-size: 0.9em;
  }
  .quote-title {
    font-weight: bold;
  }
  .quote-label {
    font-size: 0.9em;
  }
  .social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    border-radius: 35px;
    transition: .3s;
  }
  .social:hover {
    color:#ffc107;
  }
  .service-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
  }
  .service-link:hover {
    letter-spacing: 1px;
    box-shadow: none;
  }
  .service-link::before {
    position: relative;
    content: "\f105";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 10px;
  }
  @import './assets/css/bootstrap.min.css';

  .logistic-section {
    max-width: 1024px;
    min-width: 450px;
    margin: 0 auto; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
  }
</style>
  